import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Layout, AllDeveloperActivityTable, Link } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Pane, Text } from 'evergreen-ui';

const AllDeveloperActivityPage = ({ data, location }) => {
    const { edges } = data.allDeveloperActivity;
    const { dateForSEO, dateForReader } = edges[0].node;
    let title = `Blockchain Developer Activity: Stack Overflow & Stack Exchange`;
    let description = ``;
    let imageSrc = `https://stackoverflow.design/assets/img/logos/so/logo-stackoverflow.png`;
    const page = { meta_title: title, title, description };
    const newData = { ghostPage: page };

    const avgQuestionsSE = [];
    const totalQuestionsSE = [];
    const avgAnswersSE = [];
    const totalAnswersSE = [];
    const avgUsersSE = [];
    const totalUsersSE = [];
    const avgQuestionsSO = [];
    const totalQuestionsSO = [];

    for (let i = 0; i < edges.length; i++) {
        const { currency, totals, answersSE, questionsSO, slug } = edges[i].node;
        const yearsSE = answersSE.length;
        avgQuestionsSE.push({ currency, slug, number: totals.questionsSE / yearsSE });
        totalQuestionsSE.push({ currency, slug, number: totals.questionsSE });
        avgAnswersSE.push({ currency, slug, number: totals.answersSE / yearsSE });
        totalAnswersSE.push({ currency, slug, number: totals.answersSE });
        avgUsersSE.push({ currency, slug, number: totals.usersSE / yearsSE });
        totalUsersSE.push({ currency, slug, number: totals.usersSE });
        if (questionsSO) {
            const yearsSO = questionsSO.length;
            avgQuestionsSO.push({ currency, slug, number: totals.questionsSO / yearsSO });
            totalQuestionsSO.push({ currency, slug, number: totals.questionsSO });
        }
    }

    return (
        <>
            <MetaData
                data={newData}
                location={location}
                type="article"
                title={title}
                description={description}
                image={imageSrc}
            />
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        <figure className="post-feature-image">
                            <Pane marginX="auto" maxWidth={400}>
                                <Pane padding={20}>
                                    <img src="https://stackoverflow.design/assets/img/logos/so/logo-stackoverflow.svg" alt={`Stack Overflow Logo`} />
                                </Pane>
                                <Pane padding={20}>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/75/Stack_Exchange_logo_and_wordmark.svg" alt={`Stack Exchange Logo`} />
                                </Pane>
                            </Pane>
                        </figure>
                        <section className="post-full-content">
                            <Text color="muted">Updated <time datetime={dateForSEO}>{dateForReader}</time></Text>
                            <h1 className="content-title">{title}</h1>
                            <section className="content-body load-external-scripts smaller-headings">
                                <p>One potential way to gain insight into the developer activity of different crypto projects / blockchain ecosystems is by looking at the related questions, answers, and users on Stack Overflow and Stack Exchange. <a target="_blank" rel="noopener" href="https://stackoverflow.com/">Stack Overflow</a> is a Q & A site for programmers, and <a target="_blank" rel="noopener" href="https://stackexchange.com/">Stack Exchange</a> follows the Q & A format for specific niches.</p>
                                You can see the individual year-by-year Stack Overflow / Stack Exchange stats for the following: 
                                <ul>
                                    <li><Link to="/ethereum-developer-activity/">Ethereum</Link></li>
                                    <li><Link to="/bitcoin-developer-activity/">Bitcoin</Link></li>
                                    <li><Link to="/cardano-developer-activity/">Cardano</Link></li>
                                    <li><Link to="/solana-developer-activity/">Solana</Link></li>
                                    <li><Link to="/monero-developer-activity/">Monero</Link></li>
                                    <li><Link to="/tezos-developer-activity/">Tezos</Link></li>
                                    <li><Link to="/eosio-developer-activity/">EOSIO</Link></li>
                                    <li><Link to="/stellar-developer-activity/">Stellar</Link></li>
                                    {/* <li><Link to="/iota-developer-activityy/">Iota</Link></li> */}
                                </ul>
                                On this page, the totals and per year averages are compared:
                                <ul>
                                    <li><a href="#stack-exchange-average-questions">Average Stack Exchange Questions per Year</a></li>
                                    <li><a href="#stack-exchange-total-questions">Total Stack Exchange Questions</a></li>
                                    <li><a href="#stack-exchange-average-answers">Average Stack Exchange Answers per Year</a></li>
                                    <li><a href="#stack-exchange-total-answers">Total Stack Exchange Answers</a></li>
                                    <li><a href="#stack-exchange-average-users">Average Stack Exchange Users per Year</a></li>
                                    <li><a href="#stack-exchange-total-users">Total Stack Exchange Users</a></li>
                                    <li><a href="#stack-overflow-average-questions">Average Stack Overflow Questions per Year</a></li>
                                    <li><a href="#stack-overflow-total-questions">Total Stack Overflow Questions</a></li>
                                    <li><a href="#why">Why Stack Overflow / Stack Exchange for Developer Activity</a></li>
                                </ul>
                                <p><b>NOTE:</b> Some projects have been on Stack Overflow or Stack Exchange for many years, and others only a year or two. Keep this in mind when comparing totals and averages.</p>

                                <h2 id="stack-exchange-average-questions">Average Stack Exchange Questions per Year</h2>
                                <p>The average number of questions asked per year on each respective Stack Exchange.</p>
                                <AllDeveloperActivityTable rows={avgQuestionsSE} columnTitle="Average Questions per Year" />

                                <h2 id="stack-exchange-total-questions">Total Stack Exchange Questions</h2>
                                <p>The total number of questions asked on each respective Stack Exchange.</p>
                                <AllDeveloperActivityTable rows={totalQuestionsSE} columnTitle="Total Questions" />

                                <h2 id="stack-exchange-average-answers">Average Stack Exchange Answers per Year</h2>
                                <p>The average number of answers per year on each respective Stack Exchange.</p>
                                <AllDeveloperActivityTable rows={avgAnswersSE} columnTitle="Average Answers per Year" />

                                <h2 id="stack-exchange-total-answers">Total Stack Exchange Answers</h2>
                                <p>The total number of answers on each respective Stack Exchange.</p>
                                <AllDeveloperActivityTable rows={totalAnswersSE} columnTitle="Total Answers" />

                                <h2 id="stack-exchange-average-users">Average Stack Exchange Users per Year</h2>
                                <p>The average number of users that joined per year on each respective Stack Exchange.</p>
                                <AllDeveloperActivityTable rows={avgUsersSE} columnTitle="Average Users per Year" />

                                <h2 id="stack-exchange-total-users">Total Stack Exchange Users</h2>
                                <p>The total number of users that have joined each respective Stack Exchange.</p>
                                <AllDeveloperActivityTable rows={totalUsersSE} columnTitle="Total Users" />

                                <h2 id="stack-overflow-average-questions">Average Stack Overflow Questions per Year</h2>
                                <p>The average number of questions asked per year on Stack Overflow with the tag of the crypto project (for example, questions on with the tag <em>Ethereum</em>).</p>
                                <AllDeveloperActivityTable rows={avgQuestionsSO} columnTitle="Average Questions per Year" />

                                <h2 id="stack-overflow-total-questions">Total Stack Overflow Questions</h2>
                                <p>The total number of questions asked on Stack Overflow with the tag of the crypto project (for example, questions on with the tag <em>Ethereum</em>).</p>
                                <AllDeveloperActivityTable rows={totalQuestionsSO} columnTitle="Total Questions" />

                                <h2 id="why">Why Stack Overflow / Stack Exchange for Developer Activity?</h2>
                                <p>Stack Overflow and Stack Exchange have a huge network of professionals, experts, and enthusiasts. Millions of developers / software engineers use these sites to ask questions and get answers. The amount of questions, answers, and users, related to a specific blockchain / crypto project, might provide some insight into how active and healthy the developer ecosystem is.</p>
                                <Text>Source: <a target="_blank" rel="noopener" href="https://api.stackexchange.com/">Stack Exchange API</a></Text>
                            </section>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    );
};

export default AllDeveloperActivityPage;

export const postQuery = graphql`
    query {
        allDeveloperActivity {
            edges {
              node {
                slug
                dateForSEO
                dateForReader
                totals {
                  answersSE
                  questionsSE
                  questionsSO
                  usersSE
                }
                currency
                questionsSE {
                  year
                }
                questionsSO {
                  year
                }
                usersSE {
                  year
                }
                answersSE {
                  year
                }
              }
            }
          }
    }
`;
